.box-group {
  margin: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  background: white;
  padding: 1.5rem;
  justify-content: space-between;

  .box {
    padding: 0;
    width: 100%;
    flex: none;

    @media (min-width: 600px) {
      width: calc(50% - .75rem);
    }
  }
}

.box {
  background-color: white;
  margin-bottom: 1.5rem;
  padding: 1.5rem;

  &.is-body-scroller {
    .box-inner {
      display: flex;
      flex-direction: column;
      max-height: 100%;
    }

    .box-header,
    .box-footer {
      flex: 1;
    }

    .box-footer {
      margin-top: .5rem;
    }

    .box-body {
      min-height: 0;
      overflow: auto;
      overflow-x: hidden;
    }
  }

  &.is-fixed-bottom {
    position: fixed;
    bottom: 0; left: 0; right: 0;
    max-height: calc(100% - 3.75rem);
    margin-bottom: 0;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, .12), 0 -1px 2px rgba(0, 0, 0, .24);
    z-index: 100;
  }

  &.is-switchable {
    .box-switch {
      display: none;

      ~ [data-box-switch="on"] {
        display: none;
      }

      ~ [data-box-switch="off"] {
        display: flex;
      }
    }

    .box-switch:checked {
      ~ [data-box-switch="on"] {
        display: flex;
      }

      ~ [data-box-switch="off"] {
        display: none;
      }
    }
  }
}

.box-title {
  margin-bottom: .5rem;
  line-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box {
  display: flex;
  flex-direction: column;
}

.box-close {
  color: #999;
  line-height: 1;
  margin-left: 1.5rem;
  width: auto;
}
