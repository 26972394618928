.list-inline {
  display: inline;

  > li {
    display: inline;
  }
}

.list-and {
  > li {
    &:before {
      content: ", ";
    }

    &:last-child:before {
      content: " und ";
    }

    &:first-child:before {
      content: "";
    }
  }
}
