@import (less) '~choices.js/assets/styles/css/choices.css';

.choices__list--multiple .choices__item {
  align-items: center;
  background-color: whitesmoke;
  border-radius: 3px;
  color: #4a4a4a;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
  border-radius: 290486px;
  font-size: .9rem;
  border: none !important;

  &.is-highlighted {
    background: darken(whitesmoke, 10%);
  }
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  border-color: rgba(255, 255, 255, .1);
  filter: invert(100%);
}

.choices__inner {
  &:extend(.form-input);
  border-radius: 0;
}

.choices .choices__input {
  border: none;
  padding: 0;
  display: inline-block;
  width: auto;
}
