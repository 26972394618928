@import '~bulma/sass/utilities/initial-variables';

$family-sans-serif: 'Open Sans', $family-sans-serif;
$family-revoltmag: 'News Cycle', $family-sans-serif;
$family-primary: $family-revoltmag;
$primary: #b12e2e;

@import '~bulma/sass/utilities/_all';
@import '~bulma/sass/base/_all';
@import '~bulma/sass/elements/content';
@import '~bulma/sass/elements/tag';
//@import '~bulma/sass/elements/_all';
//@import '~bulma/sass/components/_all';
@import '~bulma/sass/components/level';
@import '~bulma/sass/grid/_all';
@import '~bulma/sass/layout/_all';
@import './perfect-scrollbar';
