.category {
  text-align: center;
  padding: 1.5rem;
}

.category-description {
  background-color: black;
  padding: 1.5rem;
  color: white;

  @media (max-width: 768px) {
    font-size: .9rem;
    padding: 1.5rem 0;
  }
}

.category-preview {
  position: relative;
  display: flex;
  align-items: stretch;
  background-color: white;
  margin-bottom: 1.5rem;
  padding: 1.5rem;

  @media (max-width: 600px) {
    flex-wrap: wrap;

    .category-preview-poster {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }

  .category-label {
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0;
    z-index: 10;
  }
}

.category-preview-poster {
  flex: none;
  width: 40%;
  margin-right: 1.5rem;
}

.category-preview-more {
  .content-list > li:first-child .content-preview {
    padding-top: 0;
  }
}

.category-label {
  color: white;
  padding: .25em 1.5em;
  font-size: 2.5rem;

  @supports (background-blend-mode: multiply) {
    background: url('../../img/category_backdrop.jpg') 50% 50% no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    background-position: 0 100%;
  }
}

.category-title {
  display: inline-block;
  color: white;
  font-size: 4rem;
  line-height: 1;
  padding: .5em 1em;

  a:hover &,
  &:focus &,
  &:active & {
    text-decoration: underline;
    text-decoration-skip: auto;
  }

  @media (max-width: 768px) {
    font-size: 3rem;
  }
}
