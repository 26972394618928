.content {
  h1, h2, h3 {
    color: @brand-primary;

    b, strong {
      font-weight: normal;
    }
  }

  h1 {
    line-height: 3rem;

    @media print {
      line-height: 1.1;
      margin-top: .5em;
    }
  }

  .rich-text {
    &:after {
      content: ' ';
      display: table;
      clear: both;
    }
  }

  .richtext-image.left {
    float: left;
    width: 50%;
    margin-right: .5rem;
    margin-bottom: .25rem;
  }

  .richtext-image.right {
    float: right;
    width: 50%;
    margin-left: .5rem;
    margin-bottom: .25rem;
  }
}

.content-image {
  position: relative;
  margin: 0 -1.5rem 1rem;

  img {
    display: block;
  }

  figure& {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  figcaption {
    font-size: .9rem;
    color: #777;
    margin: .5rem 0 1rem;
    padding: 0 5rem;
    text-align: center;
  }

  .content-copyright {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: .25rem;
    font-size: .8rem;
    background-color: rgba(0, 0, 0, .5);
    color: white;
    margin-left: (1.5rem - .25rem);

    &:before {
      content: "© ";
    }
  }
}

.content-image-wrapper {
  position: relative;
}

.content-info {
  color: @text-lightgrey;
  margin-bottom: 1rem;
  margin-top: -.5rem;

  .tag-list {
    margin-top: .5rem;
  }

  @media print {
    margin-bottom: 2rem;

    .tag-lst {
      margin-top: 0;
    }
  }
}

.content-nowrap {
  white-space: nowrap;
}

.content-standout {
  padding: 2rem 1.5rem;
  margin: 1rem -1.5rem;
  background-color: rgba(241, 236, 236, 0.4);
}

.content-type-paragraph {
  @media print {
    max-width: 42rem;
    margin: 0 auto;
  }
}

.content-type-gallery {
  &:extend(.content-standout all);

  > div > h2 {
    margin-top: -1rem;
  }

  > div > p {
    color: #777;
    font-size: .9rem;
    margin: 0 0 1rem;
  }
}

.content-type-embed {
  &:extend(.content-standout);
  display: flex;
  justify-content: center;

  iframe {
    max-width: 100%;
  }
}

.content-list {
  margin: 0 0 1.5rem 0;
  padding: 0;

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }

  &.is-indented {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  > li {
    display: block;
    list-style-type: none;

    &:not(:first-child) {
      margin-top: .75rem;
    }
  }
}

.content-list-small {
  font-size: .9rem;
}

.content-preview {
  display: block;
  user-select: none;

  &.is-default {
    padding: .5rem 1.5rem 1.5rem;
    background: white;
    color: #555;

    &.is-simple {
      padding: 0;

      .content-preview-image {
        margin-top: 0;
      }

      .content-image {
        margin: 0;
      }
    }

    a& {
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        .content-preview-title {
          text-decoration: underline;
          text-decoration-skip: auto;
        }
      }
    }

    .layout-content & {
      background: hsla(0, 15%, 93.5%, .4);
    }

    .content-preview-image {
      margin-top: -.5rem;
      margin-bottom: .5rem;
    }

    .content-preview-title {
      font-weight: bold;
      font-size: 1.1rem;
      margin-bottom: .25rem;
    }

    .content-preview-description {
      font-size: .9rem;
    }

    .content-preview-footer {
      margin: .5rem 0 0;
      padding: .5rem 0 0;
      border-top: 1px solid hsla(0, 15%, 93.5%, 1);
      font-size: .8rem;
      color: @text-lightgrey;
    }
  }

  &.is-mini {
    padding: .5rem 0;
    color: #555;

    a& {
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        .content-preview-title {
          text-decoration: underline;
          text-decoration-skip: auto;
        }
      }
    }

    .content-preview-body {
      display: flex;
    }

    .content-preview-image {
      flex: none;
      margin-right: 1rem;

      @media (max-width: 400px) {
        display: none;
      }

      .box-group & {
        @media (max-width: 860px) {
          display: none;
        }
      }
    }

    .content-preview-title {
      font-weight: bold;
      font-size: 1.1em;
      margin-bottom: .25rem;
    }

    .content-preview-description {
      font-size: .9em;
    }

    .content-preview-footer {
      margin: .5rem 0 0;
      padding: .5rem 0 0;
      border-top: 1px solid hsla(0, 15%, 93.5%, 1);
      font-size: .8em;
      color: @text-lightgrey;
    }
  }
}

.content-stats {
  > li:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.content-stat-body {
  padding: .5rem 0;
}

.content-stat-label {
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: .25rem 0;

  i, svg {
    margin-right: .5rem;
  }
}

.content-license {
  @media print {
    padding-left: 0;
    padding-right: 0;
  }
}
