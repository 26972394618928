@grid-gap: 1.5rem;

.grid {
  display: grid;
  row-gap: @grid-gap;

  &.has-column-gap {
    column-gap: 1.5rem;
  }

  @media (min-width: 960px) {
    &.is-3-2-1 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 768px) {
    &.is-3-2-1,
    &.is-2-2-1 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
