.gallery-scroller {
  position: relative;
  overflow: auto;
}

.gallery {
  display: block;
  white-space: nowrap;
}

.gallery-item {
  display: inline-block;
  margin: 0;

  &:not(:last-child) {
    margin-right: .5rem;
  }

  figure {
    margin: 0;

    img {
      display: block;
    }

    figcaption {
      display: none;
    }
  }
}
