:root {
  background: #eaeaea;

  @media print {
    background: white;
    font-size: 10px;
    line-height: 1.1;
  }
}

body {
  padding: 1.5rem 0 500px;

  .no-js & {
    padding-top: 5rem;
  }

  @media (max-width: 1023px) {
    padding-top: 5rem;
  }

  @media (max-width: 600px) {
    padding-top: 3rem;
  }

  @media print {
    padding: 0;
  }
}

body, button, input, select, textarea {
  font-family: @family-primary;
}

h1, h2, h3 {
  font-family: @family-accent;
  color: @brand-primary;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

abbr[title] {
  font-variant: normal;
  border-bottom: none;
  text-decoration: none;
  cursor: help;
}
