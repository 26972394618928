.form {
  > p {
    margin-bottom: .75rem;
  }

  label {
    &:extend(.form-label);
    margin-bottom: .25rem;
  }

  input, select, textarea {
    &:extend(.form-input);
  }
}

.form-label {
  display: block;
  width: 100%;
  font-size: 1rem;
}

.form-input {
  padding: .5em .75em;
  border: 2px solid #eaeaea;
  background: white;
  display: block;
  width: 100%;
  font-size: 1rem;
}

.button {
  padding: .75em 1em;
  border: none;
  background: white;
  cursor: pointer;
  font-size: 1rem;
  display: inline-block;
  user-select: none;
  transition: background-color .25s;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &[type="submit"] {
    margin-top: .75em;
  }

  &.is-block {
    width: 100%;
  }

  &.is-small {
    font-size: .75rem;
  }

  &.is-small-padding {
    padding: .5em .75em;
  }

  &.is-transparent-20 {
    background-color: rgba(0, 0, 0, .05);
  }

  &.is-default {
    @color-default: #eaeaea;
    background-color: @color-default;

    &:hover,
    &:active,
    &:focus {
      background-color: darken(@color-default, 5%);
    }
  }

  &.is-primary {
    background-color: @brand-primary;
    color: white;

    &:hover,
    &:active,
    &:focus {
      background-color: darken(@brand-primary, 5%);
    }
  }
}

.button-group {
  > *:not(:first-child) {
    margin-left: .5rem;
  }
}
