.navbar {
  user-select: none;
  background: white;
}

.navbar .container {
  display: flex;
}

.navbar-brand,
.navbar-menu,
.navbar-start,
.navbar-end {
  display: flex;
}

.navbar-end {
  margin-left: auto;
}

.navbar-brand {
  justify-content: space-between;
  flex: 0 0 auto;

  @media (max-width: 620px) {
    flex: none;
    width: 100%;
  }
}

.navbar-menu {
  flex: 1 1 auto;

  @media (max-width: 620px) {
    display: none;
  }
}

.navbar.is-fixed {
  position: fixed;
  top: 0; left: 0; right: 0;
  z-index: 1000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  height: 3.75rem;
  will-change: transform;
  transition: transform .25s;

  .navbar-switch:checked ~ & {
    box-shadow: 0 1px 0 #f0f0f0;
    transition: transform .25s, box-shadow 0s .25s;
  }

  .no-js & {
    display: block;
    transform: translateZ(0);
  }

  @media (min-width: 1024px) {
    transform: translateY(-110%);

    .navbar-show & {
      transform: translateZ(0);
    }
  }
}

.navbar-item {
  letter-spacing: 2px;
  font-family: @family-accent;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  color: #4a4a4a;
  cursor: pointer;

  a&, label& {
    &:hover,
    &:focus,
    &:active {
      background-color: @brand-primary;
      color: white;
    }
  }
}

.navbar-icon {
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-switch {
  display: none;
}

.navbar-burger {
  display: none;

  @media (max-width: 620px) {
    display: flex;
  }

  svg {
    font-size: 1.5rem;
  }
}

.navbar-mobile-menu {
  position: fixed;
  top: 3.75rem;
  z-index: 999;
  left: 0;
  right: 0;
  background: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-7.5rem);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  transition: transform .35s ease-out, opacity .1s;

  .navbar-switch:checked ~ & {
    pointer-events: all;
    transform: translateY(0);
    opacity: 1;
  }

  > .navbar-item {
    padding-left: 0;
    padding-right: 0;
  }

  > .navbar-item:not(:first-child):not(:first-child) {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-icon {
    margin-right: -1rem;
  }
}
