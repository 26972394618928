@color-twitter: #1da1f2;
@color-facebook: #3b5998;
@color-google: #dd4b39;

.share-wrapper {
  &:extend(.content-standout);
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  justify-content: center;
}

.share-title {
  font-family: @family-primary;
  font-size: 1.2rem;
  margin-right: 1.5rem;
}

.share-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  flex: 1;
  margin: 0 -.5rem;
}

.share {
  font-size: 2rem;
  margin: 0 .25rem;
  transition: color .25s, border-color .25s;
  color: #4a4a4a;
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, .1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75em;
  height: 1.75em;

  &:hover,
  &:active,
  &:focus {
    border-color: currentColor;
    outline: none;
  }

  &:empty {
    display: none;
  }

  svg {
    position: relative;
  }
}

.share-default {
  &:hover,
  &:focus,
  &:active {
    color: @brand-primary;
  }
}

.share-default-alt {
  svg {
    left: -2px;
  }
}

.share-google {
  &:hover,
  &:focus,
  &:active {
    color: @color-google;
  }
}

.share-facebook {
  &:hover,
  &:focus,
  &:active {
    color: @color-facebook;
  }
}

.share-twitter {
  &:hover,
  &:focus,
  &:active {
    color: @color-twitter;
  }
}
