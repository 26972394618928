.banner {
  background: var(--banner-color);
  padding: 1.5rem;
  position: relative;
  text-decoration: none;
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
  overflow: hidden;
  padding-right: 33.333%;
  min-height: 150px;

  .banner-title,
  .content {
    position: relative;
    z-index: 1;
  }

  .content {
    color: rgba(0, 0, 0, 0.75);
  }

  @media (max-width: 450px) {
    padding-right: inherit;
  }
}

.banner-title {
  color: white;
  font-size: 2rem;
  margin-bottom: .25em;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.banner-image {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 66.666%;
  z-index: 0;
  opacity: .5;
  background-size: cover;
  background-position: 50% 50%;
  background-image: linear-gradient(to right, var(--banner-color), var(--banner-color) 10%, transparent), var(--banner-image);

  @media (max-width: 450px) {
    left: 0;
    opacity: .1;
    background-image: var(--banner-image);
    filter: grayscale(1);
  }
}
