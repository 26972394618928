.generate-columns(@n, @i: 1) when (@i =< @n) {
  &.is-@{i}-print {
    width: (@i * 100% / @n);
  }
  .generate-columns(@n, (@i + 1));
}

@media print {
  .is-hidden-print {
    display: none;
  }

  .column {
    .generate-columns(12);
  }
}
