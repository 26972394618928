.tag.is-coloured {
  color: white;
}

.tag-list {
  margin: 0 0 -.5rem 0;

  > li {
    padding: 0;
    display: inline-block;
    margin-bottom: .5rem;
    margin-right: .25rem;
  }

  .tag {
    font-size: .9rem;
  }

  @media print {
    &:before {
      content: 'Schlagwörter: ';
    }

    > li {
      margin: 0;
      white-space: nowrap;

      &:not(:last-child):after {
        content: ', ';
        display: inline-block;
        margin-right: .2em;
      }
    }
  }
}

.tag.tag {
  @media print {
    padding: 0;
    background: none;
    color: @brand-primary;
    height: auto;
    line-height: inherit;
    font-size: inherit;
  }
}
