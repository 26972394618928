.search-form-fields {
  > li:not(:last-child) {
    margin-bottom: .5rem;
  }

  @media (min-width: 450px) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.75rem;

    > li {
      padding: 0 .75rem;

      &:first-child {
        width: 100%;
        flex: none;
      }

      &:not(:first-child) {
        flex: none;
        width: 50%;
      }
    }
  }
}

.search-description {
  display: flex;
  align-items: center;

  .readmore {
    color: #777;
    line-height: 1.2rem;

    i, svg {
      margin-right: 1rem;
    }
  }

  > :first-child {
    flex: 1;
    margin-right: 1rem;
  }
}
