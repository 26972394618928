.padded {
  padding: 1.5rem;
}

.padded-x {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.padded-y {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.spaced {
  margin: 1.5rem;
}

.spaced-x {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.spaced-y {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.responsive-object {
  padding: 0 !important;
}

.title {
  text-decoration: underline;
  text-decoration-skip: auto;
  font-size: 3rem;
  margin: 0 0 .5em;

  &:not(:first-child) {
    margin-top: 1em;
  }
}

.readmore {
  display: flex;
  align-items: center;
  line-height: 1;

  i, svg {
    margin-right: .5rem;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;

  &:focus,
  &:active {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}
