.square {
  margin: 0;
  position: relative;
  background-color: white;
  display: block;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.square-content {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;

  @supports (background-blend-mode: multiply) {
    background: url('../../img/category_backdrop.jpg') 50% 50% no-repeat;
    background-blend-mode: multiply;
  }
}
