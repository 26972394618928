.layout {
  &:extend(.container);

  @media (max-width: 1009px) {
    width: 100%;
    overflow: hidden;
  }
}

.article .layout-header,
.article .layout-main,
.article .layout-footer {
  @media (max-width: 1023px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 42rem;

    > .columns {
      margin: 0;
    }
  }
}

.layout-header {
  height: (@page-width / 4);
  background: no-repeat 0 100% @brand-primary;
  background-image: url('../../img/header_960x240.jpg');
  background-size: cover;
  transition: filter .2s;
  position: relative;

  &:hover {
    filter: grayscale(35%);
  }

  @media (max-width: 767px) {
    display: none;
  }

  .time-month-03.time-day-08 & {
    background-image: url('../../img/header_frauenkampftag_960x240.jpg') !important;
  }

  @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5) {
    background-image: url('../../img/header_1920x480.jpg');
  }

  > a {
    display: block;
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
  }
}

.layout-spaced {
  margin: .5rem 0;
}

.layout-content {
  background: white;
  padding: 1.5rem;

  @media print {
    padding: 0;
    max-width: 42rem;
    margin: 0 auto;
  }
}

.layout-main {
  margin-top: 1.5rem;

  &:focus {
    outline: none;
  }

  > .column {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media print {
    margin-top: 0;
  }
}

.layout-sidebar {
  @media (max-width: 1009px) {
    background: white;
    margin-top: 1.5rem;
  }

  @media (min-width: 1024px) {
    position: sticky;
    top: (3.75rem + 1.5rem);
  }
}

.layout-footer {
  @media print {
    border-top: 1px solid #eaeaea;
  }
}
