.logo {
  overflow: hidden;
  align-items: center;
  padding: 0 1.5rem;

  a&:hover,
  a&:focus,
  a&:active {
    background-color: transparent;
  }

  svg {
    position: absolute;
  }
}

.logo-text {
  font-family: 'Impact', @family-accent;
  font-size: 3.5rem;
  color: @brand-primary;
  line-height: 1;

  .has-category & {
    color: var(--category-color);
  }
}

.logo-image {
  display: none;
}

.logo-clippath-and-blend() {
  .logo-inner,
  .logo-backdrop,
  .logo-image {
    width: 153.72774px;
    height: 49.909269px;
    display: block;
  }

  .logo-backdrop {
    clip-path: url(#logoclip);
    background-color: @brand-primary;

    .has-category & {
      background-color: var(--category-color) !important;
      background-image: none !important;
    }
  }

  .logo-text {
    position: absolute;
    transform: translateX(-10000px);
    display: none;
  }

  .logo-image {
    background: url('../../img/category_backdrop.jpg') no-repeat 50% 50%;
    background-size: 150%;
    mix-blend-mode: multiply;
    clip-path: url(#logoclip);
  }
}

.svgclippaths.backgroundblendmode {
  .logo-clippath-and-blend();
}

@supports (clip-path: url(#logoclip)) and (mix-blend-mode: multiply) {
  .logo-clippath-and-blend();
}
