// import default variables
@import '~perfect-scrollbar/src/css/variables';

// configure custom variables
$ps-border-radius: 0;
$ps-rail-container-hover-opacity: 1;
$ps-rail-hover-opacity: 1;
$ps-bar-bg: transparent;
$ps-bar-hover-bg: #b12e2e;
$ps-bar-container-hover-bg: #b12e2e;
$ps-rail-hover-bg: transparent;
$ps-scrollbar-x-bottom: 6px;
$ps-scrollbar-x-hover-height: 12px;
$ps-scrollbar-x-rail-height: 12px;

// create default theme
@import '~perfect-scrollbar/src/css/mixins';
@import '~perfect-scrollbar/src/css/themes';
