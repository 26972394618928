.columns {
  @media (max-width: 768px) {
    &:not(.is-mobile) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.column {
  &.is-7-10 {
    width: 70%;
    flex: none;
  }

  &.is-3-10 {
    width: 30%;
    flex: none;
  }

  .columns.is-mobile &.is-full-mobile {
    @media (max-width: 500px) {
      width: 100%;
      flex: none;
    }
  }

  @media (max-width: 1023px) {
    :not(.is-mobile) > & {
      width: 100%;
      padding: 0;
    }
  }
}
