.carousel-container {
  position: relative;
}

.carousel {
  position: relative;
}

.carousel-item {
  display: block;
  text-decoration: none;

  &:not(:first-child) {
    display: none;
  }

  .carousel-ready &:not(:first-child) {
    display: block;
  }

  .carousel-page & {
    width: 960px;
    height: 384px;

    @media (max-width: 1008px) {
      width: 100vw;
      height: 25vh;
    }
  }
}

.carousel-item-inner {
  position: relative;
  max-height: 100%;
}

.carousel-caption {
  position: absolute;
  left: 0; bottom: 0; right: 0;
  padding: 1rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;

  h3 {
    margin-bottom: .25rem;
    color: inherit;
  }
}

.carousel-image {
  img {
    display: block;
  }
}

.carousel-page {
  @media (max-width: 767px) {
    display: none;
  }
}

.carousel-page .flickity-page-dots {
  position: absolute;
  top: 0;
  right: -1.5rem;
  display: none;
  width: auto;
  bottom: auto;

  @media (min-width: 1200px) {
    display: block;
  }

  @media (pointer: coarse) {
    display: none;
  }

  .dot {
    display: block;
    width: 1rem;
    height: 1rem;
    border: 2px solid rgba(177, 46, 46, .15);
    border-radius: 50%;
    margin: 0 0 .5rem 0;
    transition: background-color .25s, border-color .25s;
    background: none;
    opacity: 1;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      border-color: @brand-primary;
      cursor: pointer;
    }

    &.is-selected {
      background-color: @brand-primary;
      border-color: @brand-primary;
    }
  }
}
