@import '~flickity/dist/flickity.css';

.flickity-prev-next-button {
  background-color: hsla(0, 15%, 93.5%, 1);
  transform-origin: 50% 50%;
  transition: transform .1s;
  opacity: 1;

  &:active,
  &:hover,
  &:focus {
    opacity: 1;
    transform: translateY(-50%) scale(1.1);
    background: hsla(0, 15%, 93.5%, 1);
  }

  &:disabled {
    visibility: hidden;
  }

  &.next {
    right: 1rem;
  }

  &.previous {
    left: 1rem;
  }
}
